import React, { useState, createContext, useEffect } from "react";

export type GPTModel = "gpt-3" | "gpt-4";

const defaultModel: GPTModel = "gpt-4";

export const ModelContext = createContext<{
  model: GPTModel;
  changeModel: (model: GPTModel) => void;
}>({
  model: defaultModel,
  changeModel: () => {},
});

export const useModel = () => React.useContext(ModelContext);

export function ModelProvider(props: React.PropsWithChildren<{}>) {
  const [model, setModel] = useState<GPTModel>(() => {
    const lang = localStorage.getItem("gpt-model") as GPTModel;
    return lang ?? defaultModel;
  });

  useEffect(() => {
    if (model != defaultModel) {
      localStorage.setItem("model", model);
    }
  }, [model]);

  function changeModel(model: GPTModel) {
    setModel(model);
  }

  return (
    <ModelContext.Provider value={{ model, changeModel }}>{props.children}</ModelContext.Provider>
  );
}
