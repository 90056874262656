import "./index.css";

import React from "react";
import ReactDOM from "react-dom/client";
import { Router } from "./routes";
import { LanguageProvider } from "./context/language-context";
import { ResetContextProvider } from "./context/reset-context";
import { AuthProvider } from "./context/auth-context";
import { ModelProvider } from "./context/model-context";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <ResetContextProvider>
      <LanguageProvider>
        <ModelProvider>
          <AuthProvider>
            <Router />
          </AuthProvider>
        </ModelProvider>
      </LanguageProvider>
    </ResetContextProvider>
  </React.StrictMode>
);
