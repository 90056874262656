export type Language = "en" | "de";

export const LanguageNames: Map<Language, string> = new Map([
  ["en", "English"],
  ["de", "Deutsch"],
]);

export function detectLanguage(): Language {
  const language = navigator.language;
  if (language.startsWith("de")) {
    return "de";
  }
  return "en";
}

export const text = {
  en: {
    defaultPrompt: "You are a helpful assistant.",
    systemPromptLabel: "System:",
    userPromptLabel: "Your input:",
  },

  de: {
    defaultPrompt:
      "Du bist eine zuvorkommende künstliche Intelligenz und beantwortest alle Fragen gerne.",
    userPromptLabel: "Schreib was:",
  },
};
