import { lazy, useEffect } from "react";
import { ElementType, Suspense } from "react";
import {
  createBrowserRouter,
  createMemoryRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import { Nav } from "./components/nav";
import { useAuth } from "./context/auth-context";

const Loader = () => <div>Loading...</div>;

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );

const Chat = Loadable(lazy(() => import("./pages/chat")));

export const Router = () => {
  const auth = useAuth();

  useEffect(() => {
    console.log(auth.user);
    if (!auth.user) {
      window.location.href = "/login.html";
    }
  }, [auth.user]);

  const routes = (import.meta.env.SSR ? createMemoryRouter : createBrowserRouter)([
    auth.user && !auth.user.isPending
      ? {
          path: "/",
          element: <Background />,
          errorElement: <ErrorPage />,
          children: [
            { path: "/chat", element: <Chat /> },
            { path: "/", element: <Navigate to="/chat" /> },
          ],
        }
      : {
          path: "/",
          element: <div />,
          errorElement: <div />,
        },
  ]);

  return <RouterProvider router={routes} fallbackElement={<Loader />} />;
};

function ErrorPage() {
  return <div>Nix da</div>;
}

function Background() {
  return (
    <div className="app flex flex-row min-h-screen text-gray-600">
      <Nav />
      <Outlet />
    </div>
  );
}
