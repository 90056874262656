import React, { useState } from "react";

export const ResetContext = React.createContext({
  resetSignal: false,
  setResetSignal: () => {},
});

export const useReset = () => React.useContext(ResetContext);

export const ResetContextProvider = (props: any) => {
  const [reset, setReset] = useState(false);

  return (
    <ResetContext.Provider
      value={{
        resetSignal: reset,
        setResetSignal: () => {
          setReset(true);
          setTimeout(() => {
            setReset(false);
          });
        },
      }}
    >
      {props.children}
    </ResetContext.Provider>
  );
};
