import React, { useState, createContext, useEffect } from "react";
import * as i18n from "../i18n";

export const LanguageContext = createContext<{
  language: i18n.Language;
  changeLanguage: (lang: i18n.Language) => void;
  resetLanguage: () => void;
}>({
  language: "en",
  changeLanguage: () => {},
  resetLanguage: () => {},
});

export const useLanguage = () => React.useContext(LanguageContext);

const defaultLanguage = i18n.detectLanguage();

export function LanguageProvider(props: React.PropsWithChildren<{}>) {
  const [language, setLanguage] = useState<i18n.Language>(() => {
    const lang = localStorage.getItem("language") as i18n.Language;
    return lang ?? defaultLanguage;
  });

  useEffect(() => {
    if (language != defaultLanguage) {
      localStorage.setItem("language", language);
    }
  }, [language]);

  function changeLanguage(lang: i18n.Language) {
    setLanguage(lang);
  }

  function resetLanguage() {
    localStorage.removeItem("language");
    setLanguage(i18n.detectLanguage());
  }

  return (
    <LanguageContext.Provider value={{ language, changeLanguage, resetLanguage }}>
      {props.children}
    </LanguageContext.Provider>
  );
}
