import React, { Fragment, FunctionComponent, useContext, useEffect } from "react";
import { useAuth } from "../context/auth-context";
import { useLanguage } from "../context/language-context";
import { GPTModel, useModel } from "../context/model-context";
import { useReset } from "../context/reset-context";
import * as i18n from "../i18n";

const LanguageSwitcher = () => {
  const { language, changeLanguage } = useLanguage();
  const [open, setOpen] = React.useState(false);

  return (
    <Fragment>
      <div className="relative inline-block text-left">
        <div>
          <button
            type="button"
            className="inline-flex justify-center w-15 rounded-md border border-gray-300 shadow-sm px-2 py-2 my-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50"
            id="options-menu"
            aria-haspopup
            aria-expanded={open}
            onClick={() => setOpen(!open)}
          >
            {language}
            <svg
              className="-mr-1 ml-0 mt-1 h-4 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>

        {open && (
          <div className="mt-2 w-26 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
            <div
              className="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              {Array.from(i18n.LanguageNames.entries()).map(([lang, name]) => (
                <button
                  key={lang}
                  type="button"
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  role="menuitem"
                  onClick={() => {
                    changeLanguage(lang);
                    setOpen(!open);
                  }}
                >
                  {name}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

const LanguageSwitcher2: FunctionComponent<any> = (props) => {
  const { language, changeLanguage, resetLanguage } = useLanguage();
  const { resetSignal } = useReset();

  useEffect(() => {
    if (resetSignal) {
      resetLanguage();
    }
  }, [resetSignal]);

  return (
    <div {...props}>
      <select
        className="w-15 rounded-md border border-gray-300 shadow-sm px-2 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 bg-white"
        value={language}
        onChange={(e) => changeLanguage(e.target.value as i18n.Language)}
      >
        {Array.from(i18n.LanguageNames.entries()).map(([lang, name]) => (
          <option key={lang} value={lang}>
            {name}
          </option>
        ))}
      </select>
    </div>
  );
};

const ModelChooser: FunctionComponent<any> = (props) => {
  const { changeModel, model } = useModel();

  const models: GPTModel[] = ["gpt-3", "gpt-4"];

  return (
    <div {...props}>
      <select
        className="w-15 rounded-md border border-gray-300 shadow-sm px-2 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 bg-white"
        value={model}
        onChange={(e) => changeModel(e.target.value as GPTModel)}
      >
        {models.map((model) => (
          <option key={model} value={model}>
            {model}
          </option>
        ))}
      </select>
    </div>
  );
};

const ResetButton: FunctionComponent<any> = (props) => {
  const { setResetSignal } = useReset();

  return (
    <div className="w-full" {...props}>
      <button
        className="w-full bg-white text-gray-700 py-1 px-2 rounded"
        onClick={() => setResetSignal()}
      >
        Reset
      </button>
    </div>
  );
};

const LogoutButton: FunctionComponent<any> = (props) => {
  const { logout } = useAuth();

  return (
    <div className="w-full" {...props}>
      <button className="w-full bg-white text-gray-700 py-1 px-2 rounded" onClick={() => logout()}>
        Logout
      </button>
    </div>
  );
};

export const Nav: FunctionComponent<{}> = ({}) => {
  return (
    <div className="nav bg-gray-800 text-white px-1 flex flex-col w-fit items-center">
      <ResetButton className="w-full my-1" />
      <ModelChooser className="my-1" />
      <LanguageSwitcher2 className="w-full my-1" />
      <LogoutButton className="w-full my-1" />
    </div>
  );
};
